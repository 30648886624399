<template>
  <div class="ma-0 pa-0">
    <!-- begin::the component in full width -->
    <v-col cols="12" class="pa-0 " v-if="notFluid">
      <!-- begin:: case when usar is not in debt -->
      <div class="ma-0 pa-0" v-if="studentStatus == 1">
        <!-- begin::in case there's a score after recuperation or the component is being used to show a percentaje of a total -->
        <div class="ma-0 pa-0" v-if="replacementScore > 0 || isProgressTool">
          <v-row>
            <v-col cols="12" class="pa-1">
              <div class="ma-0 pa-2 blue-grey lighten-5 rounded">
                <!-- begin::score or progress tool title (in case there´s a title) -->
                <div class="d-none" v-if="title.length < 1"></div>
                <div
                  :class="
                    `${titleBackgroundColor} ma-0 pa-0 text-center text-white text-body-1 rounded rounded-b-0`
                  "
                  v-else
                >
                  {{ title }}
                </div>
                <!-- end::score or progress tool title (in case there´s a title) -->
                <!-- begin::score/progress content -->
                <v-row>
                  <v-col cols="6" class="pr-1">
                    <v-menu
                      open-on-hover
                      left
                      offset-x
                      :nudge-left="40"
                      :nudge-top="35"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          :class="
                            `${getScoreColor(
                              subjectType,
                              score
                            )} ma-0 px-0 rounded-lg rounded-t-0 py-3`
                          "
                        >
                          <p
                            class="text-center text-h6 font-weight-medium ma-0 white--text"
                          >
                            {{ score }}
                          </p>
                        </div>
                      </template>

                      <v-card class="pa-4" style="width:300px !important">
                        <p class="text-h6 ma-0 mb-1">
                          {{ scoreTooltipTitle }}
                        </p>
                        <p class="text-body-1 ma-0">
                          {{ scoreTooltipDescription }}
                        </p>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-divider inset vertical class="my-0 my-3 py-0"></v-divider>
                  <v-col cols="6" class="pl-1">
                    <v-menu
                      open-on-hover
                      left
                      offset-x
                      :nudge-left="140"
                      :nudge-top="40"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          :class="
                            `${getScoreColor(
                              subjectType,
                              replacementScore
                            )} ma-0 px-0 rounded-lg rounded-t-0 py-3`
                          "
                        >
                          <p
                            class="text-center text-h6 font-weight-medium ma-0 white--text"
                          >
                            {{ replacementScore }}
                          </p>
                        </div>
                      </template>

                      <v-card class="pa-4" style="width:300px !important">
                        <p class="text-h6 ma-0 mb-1">
                          {{ replacementScoreTooltipTitle }}
                        </p>
                        <p class="text-body-1 ma-0">
                          {{ replacementScoreDescription }}
                        </p>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col
                    v-if="extraordinaryReplacementScore > 0.0"
                    cols="12"
                    class="d-flex flex-column px-0 pb-2"
                  >
                    <v-divider class="mx-3 mt-n4 mb-2"></v-divider>

                    <v-menu
                      open-on-hover
                      left
                      offset-x
                      :nudge-left="110"
                      :nudge-top="80"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          v-bind="attrs"
                          v-on="on"
                          :class="
                            `${getScoreColor(
                              subjectType,
                              extraordinaryReplacementScore
                            )} rounded d-flex justify-center align-center py-2 mx-3 mb-1`
                          "
                        >
                          <p
                            class="text-center text-h6 font-weight-medium ma-0 white--text"
                          >
                            {{ extraordinaryReplacementScore }}
                          </p>
                        </div>
                      </template>

                      <v-card class="pa-4" style="width:300px !important">
                        <p class="text-h6 ma-0 mb-1">
                          {{ extraordinaryReplacementScoreTooltipTitle }}
                        </p>
                        <p class="text-body-1 ma-0">
                          {{ extraordinaryReplacementScoreTooltipDescription }}
                        </p>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row>
                <!-- end::score/progress content -->
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- end::in case there's a score after recuperation or the component is being used to show a percentaje of a total -->

        <!-- begin::Evaluation score -->
        <div class="ma-0 pa-0" v-else>
          <v-row class="d-flex justify-center align-center">
            <v-col cols="10">
              <div class="ma-0 pa-0">
                <!-- begin::score title (in case there´s a title) -->
                <div
                  :class="
                    `${titleBackgroundColor} ma-0 pa-0 text-center text-white text-body-1 rounded rounded text-center`
                  "
                >
                  {{ title }}
                </div>
                <!-- end::score title (in case there´s a title) -->

                <!-- begin::score content -->
                <div
                  :class="
                    `${getScoreColor(
                      subjectType,
                      score
                    )} ma-0 px-0 rounded py-3`
                  "
                  v-if="isUpdated"
                >
                  <!-- <div
                  :class="
                    `${getScoreColor(
                      subjectType,
                      score
                    )} ma-0 px-0 rounded py-3`
                  "
                  v-if="score > 0"
                > -->
                  <p
                    class="pa-0 ma-0 text-h6 text-center font-weight-medium text-white"
                  >
                    {{ score }}
                  </p>
                </div>
                <!-- end::score content -->

                <!-- begin::fallback when there isn't any score yet -->
                <div :class="`grey lighten-1 ma-0 px-0 rounded py-3`" v-else>
                  <v-menu
                    open-on-hover
                    left
                    offset-x
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        v-bind="attrs"
                        v-on="on"
                        class="pa-0 ma-0 text-h6 text-center font-weight-medium text-white"
                      >
                        NPI
                      </p>
                    </template>

                    <v-card class="pa-4" style="width:300px !important">
                      <p class="text-h6 ma-0 mb-1">
                        Nota Pendiente de Ingresar
                      </p>
                      <p class="text-body-1 ma-0">
                        Aún se está evaluando o está siendo calificado.
                      </p>
                    </v-card>
                  </v-menu>
                </div>
                <!-- begin::fallback when there isn't any score yet -->
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- end::Evaluation score -->
      </div>
      <!-- end:: case when usar is not in debt -->
      <!-- begin:: case when user is in debt -->
      <div v-else-if="studentStatus == 0">
        <v-row class="d-flex justify-center">
          <v-col cols="12" class="pb-2 pt-2">
            <v-menu
              :nudge-left="40"
              :nudge-top="45"
              open-on-hover
              left
              offset-x
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-alert text class="ma-0 pa-1" v-bind="attrs" v-on="on">
                  <v-alert
                    color="warning"
                    class="ma-0 rounded pa-3 d-flex justify-center"
                  >
                    <v-icon>
                      mdi-clock-alert-outline
                    </v-icon>
                  </v-alert>
                </v-alert>
              </template>

              <v-card class="pa-4" style="width:300px !important">
                <p class="text-h6 ma-0 mb-1">Aviso</p>
                <p class="text-body-1 ma-0">
                  En nuestros registros se refleja mora en el pago.
                  <strong>
                    Si existe error favor comunicarse al 2234-6030 ó al correo
                    contabilidad@ricaldone.edu.sv y anexando un comprobante de
                    pago válido.</strong
                  >
                </p>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <!-- end:: case when user is in debt -->
    </v-col>
    <!-- end::the component in full width -->

    <!-- ------------------------------------------------------------------- -->

    <!-- begin::The score showed in stage -->
    <v-col cols="12" class="pa-0 ma-0" v-if="stageScore">
      <div class="ma-0 pa-0" v-if="studentStatus == 1">
        <!-- this will show if the student has entered and finished the score recuperation process; or, the component has been set to be a progress tool (it cannot be both at the same time)-->
        <div class="ma-0 pa-0" v-if="replacementScore > 0">
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="pa-0">
              <div class="pa-0">
                <!-- begin::score title (in case there´s a title) -->
                <div class="d-none" v-if="title.length < 1"></div>
                <div
                  :class="
                    `${titleBackgroundColor} ma-0 pa-0 text-center text-white text-body-1 rounded rounded-b-0`
                  "
                  v-else
                >
                  {{ title }}
                </div>
                <!-- end::score title (in case there´s a title) -->

                <!-- begin::score and replacement score content -->
                <v-row
                  class="white rounded ma-1 py-0 px-2 d-flex justify-center align-center"
                  style="border:2px solid red"
                >
                  <!-- begin::stage score -->
                  <v-col
                    :class="
                      `${getScoreColor(
                        subjectType,
                        score
                      )} rounded d-flex justify-center align-center py-2`
                    "
                  >
                    <v-menu
                      open-on-hover
                      left
                      offset-x
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          class="text-center text-h6 font-weight-medium ma-0 white--text"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ score }}
                        </p>
                      </template>

                      <v-card class="pa-4" style="width:300px !important">
                        <p class="text-h6 ma-0 mb-1">Nota ordinaria</p>
                        <p class="text-body-1 ma-0">
                          Esta es la nota de la etapa,
                          <strong>previa al proceso de recuperación</strong> .
                        </p>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <!-- end::stage score -->
                  <v-divider
                    inset
                    vertical
                    style="height:35px"
                    class="my-0 my-3 mx-1 py-0"
                  ></v-divider>
                  <!-- begin::stage replacement score -->
                  <v-col
                    :class="
                      `${getScoreColor(
                        subjectType,
                        replacementScore
                      )} rounded d-flex justify-center align-center py-2`
                    "
                  >
                    <v-menu
                      open-on-hover
                      left
                      offset-x
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <p
                          class="text-center text-h6 font-weight-medium ma-0 white--text"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ replacementScore }}
                        </p>
                      </template>

                      <v-card class="pa-4" style="width:300px !important">
                        <p class="text-h6 ma-0 mb-1">
                          Nota de recuperación ordinaria
                        </p>
                        <p class="text-body-1 ma-0">
                          Esta es la nota de la etapa,
                          <strong
                            >después del proceso de recuperación en periodo
                            ordinario</strong
                          >.
                        </p>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <!-- end::stage replacement score -->

                  <!-- begin::stage extraordinary replacement score -->
                  <v-col
                    v-if="extraordinaryReplacementScore > 0.0"
                    cols="12"
                    class="d-flex flex-column px-0 pb-2"
                  >
                    <v-divider class=" mt-n3 mb-2"></v-divider>
                    <div
                      :class="
                        `${getScoreColor(
                          subjectType,
                          extraordinaryReplacementScore
                        )} rounded d-flex justify-center align-center py-2`
                      "
                    >
                      <v-menu
                        open-on-hover
                        left
                        offset-x
                        :nudge-left="110"
                        :nudge-top="80"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <p
                            class="text-center text-h6 font-weight-medium ma-0 white--text"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ extraordinaryReplacementScore }}
                          </p>
                        </template>

                        <v-card class="pa-4" style="width:300px !important">
                          <p class="text-h6 ma-0 mb-1">
                            Nota de recuperación extraordinaria
                          </p>
                          <p class="text-body-1 ma-0">
                            Esta es la nota de la etapa,
                            <strong
                              >después del proceso de recuperación en periodo
                              extraordinario</strong
                            >.
                          </p>
                        </v-card>
                      </v-menu>
                    </div>
                  </v-col>
                  <!-- end::stage extraordinary replacement score -->
                </v-row>

                <!-- end::score and replacement score content -->
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- begin:: case when there's no recuperation score -->
        <div class="ma-0 pa-0" v-else>
          <v-row
            class="ma-1 pa-2 white rounded  d-flex justify-center justify-sm-end"
          >
            <!-- begin:: score content -->
            <v-col
              v-if="isUpdated"
              :class="
                `${getScoreColor(
                  subjectType,
                  score
                )} rounded d-flex justify-center align-center py-2`
              "
            >
              <v-menu
                open-on-hover
                left
                offset-x
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <p
                    class="text-center text-h6 font-weight-medium ma-0 white--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ score }}
                  </p>
                </template>

                <v-card class="pa-4" style="width:300px !important">
                  <p class="text-h6 ma-0 mb-1">
                    {{
                      subjectType == 1
                        ? "Nivel de logro"
                        : `Nota de ${
                            currentUserPersonalInfo.grade_id > 3
                              ? "periodo"
                              : "trimestre"
                          }`
                    }}
                  </p>
                  <p class="text-body-1 ma-0">
                    {{
                      subjectType == 1
                        ? "Nivel de logro obtenido en el módulo."
                        : `Promedio de todas notas obtenidas en los perfiles de un ${
                            currentUserPersonalInfo.grade_id > 3
                              ? "periodo"
                              : "trimestre"
                          }`
                    }}
                  </p>
                </v-card>
              </v-menu>
            </v-col>
            <!-- end:: score content -->

            <!-- begin::fallback when there isn't any score yet -->
            <v-col
              v-else
              class="
          grey lighten-1 rounded d-flex justify-center align-center py-4`
        "
            >
              <v-menu
                open-on-hover
                left
                offset-x
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <p
                    class="text-center text-body-1 font-weight-normal ma-0 white--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ subjectType == 1 ? "NPI" : "No disponible" }}
                  </p>
                </template>

                <v-card class="pa-4" style="width:300px !important">
                  <p class="text-h6 ma-0 mb-1">
                    {{
                      subjectType == 1
                        ? "Módulo no empezado"
                        : "Promedio de Etapa No Disponible"
                    }}
                  </p>
                  <p class="text-body-1 ma-0">
                    {{
                      subjectType == 1
                        ? "Este módulo aún no se ha comenzado a trabajar o evaluar."
                        : "Aún no se ha comenzado a evaluar esta etapa."
                    }}
                  </p>
                </v-card>
              </v-menu>
            </v-col>
            <!-- end::fallback when there isn't any score yet -->
          </v-row>
        </div>
        <!-- end:: case when there's no recuperation score -->
      </div>
      <!-- begin:: case when user is in debt -->
      <div v-else-if="studentStatus == 0">
        <v-row class="d-flex justify-center">
          <v-col cols="12" class="pb-5 pt-2">
            <v-menu open-on-hover left offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-alert text class="ma-0 pa-1" v-bind="attrs" v-on="on">
                  <v-alert
                    color="warning"
                    class="ma-0 rounded pa-3 d-flex justify-center"
                  >
                    <v-icon>
                      mdi-clock-alert-outline
                    </v-icon>
                  </v-alert>
                </v-alert>
              </template>

              <v-card class="pa-4" style="width:300px !important">
                <p class="text-h6 ma-0 mb-1">Aviso</p>
                <p class="text-body-1 ma-0">
                  En nuestros registros se refleja mora en el pago.
                  <strong>
                    Si existe error favor comunicarse al 2234-6030 ó al correo
                    contabilidad@ricaldone.edu.sv y anexando un comprobante de
                    pago válido.</strong
                  >
                </p>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </div>
      <!-- end:: case when user is in debt -->
    </v-col>
    <!-- end::The score showed in stage -->

    <!-- ------------------------------------------------------------------- -->

    <!-- begin::Score showed in summary -->
    <div v-if="summaryScore">
      <!-- begin:: case when user is not in debt -->
      <div v-if="studentStatus == 1">
        <!-- begin::In case there's a recuperation score -->
        <div
          class="ma-0 pa-0 d-flex justify-center align-center "
          v-if="replacementScore > 0"
        >
          <p :class="`ma-0 text-center text-body-1 font-weight-bold`">
            <span :class="`${getScoreColor(subjectType, score)}--text`">{{
              score
            }}</span>
            →
            <span
              :class="`${getScoreColor(subjectType, replacementScore)}--text`"
              >{{ replacementScore }}</span
            >
          </p>
        </div>
        <!-- end::In case there's a recuperation score -->

        <!-- begin::summary score content -->
        <div v-else>
          <!-- begin::summary score -->
          <v-menu
            v-if="isUpdated"
            open-on-hover
            bottom
            nudge-bottom="5px"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <p
                v-bind="attrs"
                v-on="on"
                :class="
                  `ma-0 text-center text-h6 font-weight-bold ${getScoreColor(
                    subjectType,
                    score
                  )}--text`
                "
              >
                {{ score }}
              </p>
            </template>

            <v-card
              class="pa-4"
              :style="
                `${
                  subjectType == 1 ? 'width:200px' : 'width:150px'
                } !important; cursor:pointer`
              "
            >
              <p class="text-body-1 font-weight-medium text-center ma-0">
                {{
                  subjectType == 1 ? "Nivel de logro obtenido" : "Nota obtenida"
                }}
              </p>
            </v-card>
          </v-menu>
          <!-- end::summary score -->
          <!-- begin::Fallback when there isn't a score yet -->
          <v-menu
            v-else
            open-on-hover
            top
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <p
                v-bind="attrs"
                v-on="on"
                :class="`ma-0 text-center text-h6 font-weight-bold grey--text`"
              >
                NPI
              </p>
            </template>

            <v-card class="pa-4" style="width:300px !important">
              <p class="text-h6 ma-0 mb-1">
                {{
                  subjectType == 1
                    ? "Nivel de logro pediente de ingresar"
                    : "Nota Pendiente de Ingresar"
                }}
              </p>
              <p class="text-body-1 ma-0">
                Aún no se ha comenzado a evaluar o está siendo calificada.
              </p>
            </v-card>
          </v-menu>
          <!-- end::Fallback when there isn't a score yet -->
        </div>
        <!-- end::summary score content -->
      </div>
      <!-- end:: case when user is not in debt -->
      <!-- begin:: case when user is in debt -->
      <div v-if="studentStatus == 0">
        <v-icon class="my-1" color="orange">
          mdi-clock-alert-outline
        </v-icon>
      </div>
      <!-- end:: case when user is in debt -->
    </div>
    <!-- end::Score showed in summary -->

    <!-- ------------------------------------------------------------------- -->

    <!-- begin::Score showed in category -->
    <div v-if="categoryScore">
      <!-- begin:: case when user is not in debt -->
      <div v-if="studentStatus == 1">
        <!-- begin::In case there's a recuperation score -->
        <div
          class="ma-0 pa-0 d-flex justify-center align-center "
          v-if="
            categoryReplacementScoreVisibility(
              subjectType,
              score,
              replacementScore
            )
          "
        >
          <v-menu
            v-if="isUpdated"
            open-on-click
            bottom
            nudge-bottom="5px"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="elevation-2 white pa-1 mx-n3 pa-sm-2 mx-sm-n2 rounded"
                v-bind="attrs"
                v-on="on"
              >
                <p :class="`ma-0 text-body-1 font-weight-bold blue-grey--text`">
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> antes
                  del proceso de recuperación:
                  <span :class="`${getScoreColor(subjectType, score)}--text`">{{
                    score
                  }}</span>
                  <v-divider class="my-1"></v-divider>
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> después
                  del proceso de recuperación:
                  <span
                    :class="
                      `${getScoreColor(subjectType, replacementScore)}--text`
                    "
                    >{{ replacementScore }}</span
                  >
                </p>
              </div>
            </template>

            <v-card
              class="pa-4"
              :style="
                `${
                  subjectType == 1 ? 'width:320px' : 'width:320px'
                } !important; cursor:pointer`
              "
            >
              <p class="text-body-1 font-weight-medium text-center ma-0">
                {{ scoreTooltipDescription }}
              </p>
            </v-card>
          </v-menu>

          <v-menu
            v-if="score < 1"
            open-on-click
            top
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="white" label>
                <p
                  v-bind="attrs"
                  v-on="on"
                  :class="
                    `ma-0 text-center text-h6 font-weight-bold grey--text`
                  "
                >
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> de fase:
                  NPI
                </p>
              </v-chip>
            </template>

            <v-card class="pa-4" style="width:300px !important">
              <p class="text-h6 ma-0 mb-1">
                {{
                  subjectType == 1
                    ? "Nivel de logro (N.L.) pediente de ingresar"
                    : "Nota Pendiente de Ingresar"
                }}
              </p>
              <p class="text-body-1 ma-0">
                Aún no se ha comenzado a evaluar o está siendo calificada.
              </p>
            </v-card>
          </v-menu>
        </div>
        <!-- end::In case there's a recuperation score -->

        <!-- begin::summary score content -->
        <div v-else>
          <!-- begin::summary score -->
          <v-menu
            v-if="isUpdated"
            open-on-click
            bottom
            nudge-bottom="5px"
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="white"
                class="elevation-2"
                label
                v-bind="attrs"
                v-on="on"
              >
                <p
                  :class="
                    `ma-0 text-center text-h6 font-weight-medium blue-grey--text`
                  "
                >
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> de fase:
                  <span
                    :class="
                      `${getScoreColor(
                        subjectType,
                        score
                      )}--text font-weight-medium`
                    "
                    >{{ score }}</span
                  >
                </p>
              </v-chip>
            </template>

            <v-card
              class="pa-4"
              :style="
                `${
                  subjectType == 1 ? 'width:250px' : 'width:250px'
                } !important; cursor:pointer`
              "
            >
              <p class="text-body-1 font-weight-medium text-center ma-0">
                {{
                  subjectType == 1
                    ? "Nivel de logro (N.L.) obtenido"
                    : "Nota obtenida"
                }}
              </p>
            </v-card>
          </v-menu>
          <!-- end::summary score -->
          <!-- begin::Fallback when there isn't a score yet -->

          <v-menu
            v-else
            open-on-click
            top
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip class="white" label>
                <p
                  v-bind="attrs"
                  v-on="on"
                  :class="
                    `ma-0 text-center text-h6 font-weight-bold grey--text`
                  "
                >
                  <span>{{ subjectType == 1 ? "N.L." : "Nota" }}</span> de fase:
                  NPI
                </p>
              </v-chip>
            </template>

            <v-card class="pa-4" style="width:320px !important">
              <p class="text-h6 ma-0 mb-1">
                {{
                  subjectType == 1
                    ? "Nivel de logro (N.L.) pediente de ingresar"
                    : "Nota Pendiente de Ingresar"
                }}
              </p>
              <p class="text-body-1 ma-0">
                Aún no se ha comenzado a evaluar o está siendo calificada.
              </p>
            </v-card>
          </v-menu>
          <!-- end::Fallback when there isn't a score yet -->
        </div>
        <!-- end::summary score content -->
      </div>
      <!-- end:: case when user is not in debt -->
      <!-- begin:: case when user is in debt -->
      <div v-if="studentStatus == 0">
        <v-icon class="my-1" color="orange">
          mdi-clock-alert-outline
        </v-icon>
      </div>
      <!-- end:: case when user is in debt -->
    </div>
    <!-- end::Score showed in category -->

    <!-- ------------------------------------------------------------------- -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ScoreViewer",
  props: {
    //True show the "*score*" box as the actual score, otherwise NPI
    isUpdated: [Boolean],

    //Academic (2) or technical module (1)
    subjectType: {
      type: Number,
    },

    //---------------------------------------------------
    score: {
      type: [Number, String],
    },
    scoreTooltipTitle: {
      type: String,
      default: "Nota ordinaria",
    },
    scoreTooltipDescription: {
      type: String,
      default: "Obtenida de la sumatoria de notas finales.",
    },

    replacementScore: {
      type: [Number, String],
    },
    replacementScoreTooltipTitle: {
      type: String,
      default: "Nota de recuperación",
    },
    replacementScoreDescription: {
      type: String,
      default:
        "nota obtenida en el proceso de recuperación en periodo ordinario",
    },

    extraordinaryReplacementScore: {
      type: [Number, String],
    },
    extraordinaryReplacementScoreTooltipTitle: {
      type: String,
      default: "Nota de recuperación extraordinaria",
    },
    extraordinaryReplacementScoreTooltipDescription: {
      type: String,
      default:
        "nota obtenida en el proceso de recuperación en periodo extraordinario",
    },

    //---------------------------------------------------

    isProgressTool: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    titleFontColor: {
      type: String,
    },
    titleBackgroundColor: {
      type: String,
    },

    //---------------------------------------------------
    //this is the one mostly used
    notFluid: {
      type: Boolean,
    },
    stageScore: {
      type: Boolean,
    },
    summaryScore: {
      type: Boolean,
    },
    categoryScore: {
      type: Boolean,
    },

    //---------------------------------------------------
    //Its better to use the vuex state for the student status
    studentStatus: {
      type: Number,
    },
  },
  methods: {
    //Validates whether the category replacement score is in range
    categoryReplacementScoreVisibility(subType, score, replacementScore) {
      if (subType == 1) {
        if (replacementScore > 0 && score < 3.5) {
          return true;
        }
      } else {
        if (replacementScore > 0 && score < 6) {
          return true;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
};
</script>

<style scoped>
.v-sheet.v-card {
  margin-right: 0 !important;
}
</style>
