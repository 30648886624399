<template>
  <!-- begin::Parent SubEvaluationCard component container (needed in order to make the "v-for" directive valid)-->
  <v-container class="pa-0">
    <!-- begin::iterable part of the component-->
    <v-row
      class="ma-5 pa-sm-5 pa-3 pr-md-6 d-flex justify-center elevation-3 rounded-lg grey lighten-4"
      v-for="(subEvaluation, indexSub) in subEvaluationInfo.sub_evaluations"
      :key="indexSub"
    >
      <!-- begin::Subevaluation index correlative position-->
      <v-col cols="12" sm="1" md="1" class=" px-0 pr-md-4 py-1 pt-sm-1">
        <p class="text-h5 mr-0 mr-sm-2 mr-md-0 mb-0 text-center text-sm-right">
          <v-chip dark color="blue lighten-2">
            {{ indexSub + 1 }}
          </v-chip>
        </p>
      </v-col>
      <!-- end::Subevaluation index correlative position-->

      <!-- begin::Subevaluation info details-->
      <v-col cols="12" sm="8" md="9">
        <v-row>
          <v-row>
            <v-col cols="12">
              <div
                class="d-flex flex-column align-center align-sm-start ma-0 pa-0"
              >
                <!-- begin:: Evaluation type and weight -->
                <p
                  class="text-h5 text-center text-sm-left font-weight-medium ma-0 pa-0 "
                >
                  {{ subEvaluation.evaluation_type }}
                  <span>
                    {{ `(${subEvaluation.percent_formatted})` }}
                  </span>
                  <span v-if="lastWeek(subEvaluation.limit_date)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip color="red">
                          <v-icon color="white" v-bind="attrs" v-on="on">
                            mdi-clock-alert-outline
                          </v-icon>
                        </v-chip>
                      </template>
                      <span>Última semana de evaluación</span>
                    </v-tooltip>
                  </span>
                  <span v-else class="d-none"></span>
                </p>
                <!-- end:: Evaluation type and weight -->

                <!-- begin:: SubEvaluation period -->
                <div
                  class="d-flex flex-column flex-sm-row justify-center align-center"
                >
                  <p class="mb-0 text-center">
                    Finaliza el
                    <span class="font-weight-medium">
                      {{ subEvaluation.limit_date_formatted }}
                    </span>
                  </p>
                </div>
                <!-- end:: SubEvaluation period -->
              </div>
            </v-col>
          </v-row>

          <!-- begin::Subevaluation description-->
          <v-col class="px-0" cols="12">
            <p class="text-justify text-body-1 mb-0">
              {{ subEvaluation.description }}
            </p>
            <div
              class="ma-0 pa-0"
              v-if="subEvaluation.evaluation_instrument_document == null"
            ></div>
            <div class="ma-0 pa-0" v-else>
              <a
                :href="evaluationInstrumentURL + subEvaluation.idSE"
                target="_blank"
                class="d-flex my-2 mb-1 mb-sm-n1 mt-sm-2 justify-center justify-sm-start align-center"
              >
                <v-icon class="mr-1" color="red">mdi-file-pdf-box</v-icon>
                <span class="font-weight-medium black--text text-center "
                  >Instrumento de evaluación</span
                >
              </a>
            </div>
          </v-col>

          <!-- end::Subevaluation description-->
        </v-row>
      </v-col>
      <!-- end::Subevaluation info details-->

      <!-- begin::Subevaluation score details and decoration-->

      <v-row class="ma-0 pa-0 d-flex justify-center align-center">
        <v-col cols="6" sm="8" class="ma-0 pa-0">
          <ScoreViewer
            title=""
            :isUpdated="subEvaluation.is_updated_score"
            :score="subEvaluation.score"
            :replacementScore="0"
            :notFluid="true"
            :studentStatus="studentInfo"
            :subjectType="subjectType"
          ></ScoreViewer>
        </v-col>
      </v-row>
      <!-- end::Subevaluation score details and decoration-->
    </v-row>
    <!-- end::iterable part of the component-->
  </v-container>
  <!-- end::Parent SubEvaluationCard component container-->
</template>

<script>
import ScoreViewer from "@/view/components/evaluation/ScoreViewer.vue";
export default {
  name: "SubEvaluationCard",
  props: {
    studentInfo: {
      type: Number,
    },
    subEvaluationInfo: {
      type: Object,
    },
    evaluation_StartDate: {
      type: String,
    },
    evaluation_EndDate: {
      type: String,
    },
    subjectType: {
      type: Number,
    },
  },
  components: {
    ScoreViewer,
  },
  methods: {
    //checks whether or not the evaluation state is "próximo a realizar", "en ejecución" o "cerrado"
    evaluationStateProgress(start, end) {
      //obtaining today's date in ms
      const today = new Date().getTime();

      //obtaining the end of the evaluation period date in ms
      const evaluationEndDate = new Date(end);
      evaluationEndDate.setDate(evaluationEndDate.getDate() + 1);

      //obtaining the start of the evaluation period date in ms
      const evaluationStartDate = new Date(start);
      evaluationStartDate.setDate(evaluationStartDate.getDate());

      //Comparing evaluation period start and end with todays's date to get the evaluation status ID
      if (evaluationStartDate.getTime() - today > 0) {
        //"próximo a realizar"
        return 1;
      } else if (
        evaluationStartDate.getTime() - today < 0 &&
        evaluationEndDate.getTime() - today > 0
      ) {
        //"en ejecución"
        return 2;
      } else if (evaluationEndDate.getTime() - today < 0) {
        //"cerrado"
        return 3;
      }
    },

    lastWeek(limitDate) {
      //obtaining today's date in ms
      const today = new Date().getTime();

      //obtaining the end of the subEvaluation date in ms
      const subEvaluationLimitDate = new Date(limitDate);
      subEvaluationLimitDate.setDate(subEvaluationLimitDate.getDate() + 1);

      let week = 7 * 24 * 60 * 60 * 1000;

      //comparing
      if (
        subEvaluationLimitDate - today > 0 &&
        subEvaluationLimitDate - today < week
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    evaluationInstrumentURL() {
      let baseURL;
      if (process.env.GE_ENV === "production") {
        baseURL = process.env.GE_API_URL;
      } else if (process.env.GE_ENV === "development") {
        baseURL = process.env.GE_DEV_API_URL;
      } else {
        baseURL = process.env.GE_LOCAL_API_URL;
      }
      return baseURL + "/sub-evaluations/evaluation-instrument/";
    },
  },
};
</script>
