import GeStudentApiService from "@/core/services/geStudentApi.service";

/**
 * GET request to fetch data by subject_teacher_id and stage id
 * @param subject_teacher_id
 * @param stage_id
 * @returns {*}
 */
export const getAcademicEvaluationsBySubjectTeacher = (
  subject_teacher_id,
  stage_id
) => {
  return GeStudentApiService.get(
    "evaluations/academics/subject-teachers",
    subject_teacher_id,
    stage_id
  );
};

/**
 * GET request to fetch data by subject_teacher_id (gets all evaluations of one subject)
 * @param subject_teacher_id
 * @returns {all evaluations by subject_teacher_id}
 */
export const getAllEvaluationsBySubject = (subject_teacher_id) => {
  return GeStudentApiService.get("evaluations/subject-teachers", subject_teacher_id);
};

/**
 * GET request to fetch data by subject_teacher_id
 * @param subject_teacher_id
 * @returns {Modular evaluations by subject_teacher_id}
 */
export const getModularEvaluationsBySubjectTeacher = (subject_teacher_id) => {
  return GeStudentApiService.get(
    "evaluations/academics/subject-teachers",
    subject_teacher_id
  );
};

/**
 * GET request to fetch all evaluation scores
 * @returns {*}
 */
export const getAllEvaluationScores = () => {
  return GeStudentApiService.get("subjects/scores");
};

/**
 * GET request to fetch all Academic evaluation scores
 * @returns {*}
 */
export const getAllAcademicEvaluationScores = () => {
  return GeStudentApiService.get("subjects/academics/scores-average");
};

/**
 * GET request to fetch all Technical evaluation scores
 * @returns {*}
 */
export const getAllTechnicalEvaluationScores = () => {
  return GeStudentApiService.get("subjects/technicals/scores-average");
};

export default {
  getAcademicEvaluationsBySubjectTeacher,
  getModularEvaluationsBySubjectTeacher,
  getAllEvaluationsBySubject,
  getAllEvaluationScores,
  getAllAcademicEvaluationScores,
  getAllTechnicalEvaluationScores,
};
