import GeStudentApiService from "@/core/services/geStudentApi.service";

/**
 * GET request to fetch all subjects
 * @returns {*}
 */
export const getSubjects = () => {
  return GeStudentApiService.get("subjects/academics");
};

/**
 * GET request to fetch all modules
 * @returns {*}
 */
export const getModules = () => {
  return GeStudentApiService.get("subjects/technicals");
};

/**
 * GET request to fetch subjects by subject_teacher_id
 * @param subject_teacher_id
 * @returns {*}
 */
export const getSubjectbyTeacherId = (subject_teacher_id) => {
  return GeStudentApiService.get("subjects/subject-teachers", subject_teacher_id);
};

export default {
  getSubjects,
  getModules,
  getSubjectbyTeacherId,
};
