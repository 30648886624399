<template>
  <!-- begin::EvaluationCard component-->
  <v-row class="d-flex justify-center justify-xl-center align-top px-n10 mt-2">
    <v-col
      cols="12"
      lg="11"
      xl="8"
      v-for="(item, index) in evaluationInfo"
      :key="index"
      class="align-self-start"
    >
      <div class="ma-0 pa-0">
        <!-- begin::EvaluationCard activity_category tab-->
        <v-row class="ma-0 pa-0">
          <v-col class="mb-0 ml-0 ml-sm-3 pa-0" cols="12" sm="5">
            <div
              :class="
                `${
                  colors[item.activity_category_id % 12]
                } white--text mb-n2 rounded-t-lg`
              "
            >
              <h5 class="pa-1 text-center font-weight-medium subtitle tab">
                {{ item.activity_category }} ({{ parseFloat(item.percent) }}%)
              </h5>
            </div>
          </v-col>
        </v-row>
        <!-- end::EvaluationCard activity_category tab-->

        <!-- begin::"folder like" evaluations container -->
        <v-row
          class="blue-grey lighten-4 ma-0 rounded mx-n5 mx-sm-0"
          v-if="item.evaluations.length > 0"
        >
          <v-col cols="12" class="mx-0">
            <!-- begin:: The total percentage obtained at the fase by period|trimester|module -->
            <v-col
              cols="12"
              sm="8"
              class="pt-0 px-0 px-sm-2 d-flex justify-center justify-sm-start"
            >
              <ScoreViewer
                :isUpdated="item.is_updated_score"
                :score="item.final_score"
                :replacementScore="item.recovery_score"
                :categoryScore="true"
                :studentStatus="studentInfo"
                :subjectType="subjectType"
              ></ScoreViewer>
            </v-col>
            <!-- end:: The total percentage obtained at the fase by period|trimester|module -->

            <!-- begin:: Evaluation card, contains the evaluation and sub-evalautions of subjects by period|trimester|module -->
            <v-card
              class="ma-0 mb-5 pt-3 pb-1 rounded-lg grey lighten-5 elevation-2"
              v-for="(evaluation, idx) in item.evaluations"
              :key="idx"
            >
              <!-- begin::Evaluation Card Title -->
              <v-row class="px-6">
                <v-col
                  class="d-flex flex-column flex-sm-row justify-space-between align-center"
                >
                  <div class="d-flex flex-column align-center align-sm-start">
                    <!-- begin:: Evaluation type and weight -->
                    <p
                      class="text-h5 text-center text-sm-left font-weight-medium mb-0"
                    >
                      {{ evaluation.activity_type }}
                      <span>
                        {{ `(${evaluation.percent_formatted})` }}
                      </span>
                    </p>
                    <!-- end:: Evaluation type and weight -->

                    <!-- begin:: Evaluation modality and period -->
                    <div
                      class="d-flex flex-column flex-sm-row justify-center align-center"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="blue darken-1"
                            v-bind="attrs"
                            v-on="on"
                            >{{
                              evaluation.is_group_activity === "Individual"
                                ? "mdi-account"
                                : "mdi-account-group"
                            }}</v-icon
                          >
                        </template>
                        <span>{{
                          evaluation.is_group_activity === "Individual"
                            ? "Actividad individual"
                            : "Actividad grupal"
                        }}</span>
                      </v-tooltip>
                      <p class="mb-0 mx-2 d-none d-md-flex">•</p>
                      <p class="mb-0 text-center">
                        Inicia el
                        <span class="font-weight-medium">
                          {{ evaluation.start_date_formatted }}
                        </span>
                        y finaliza el
                        <span class="font-weight-medium">
                          {{ evaluation.end_date_formatted }}
                        </span>
                      </p>
                    </div>
                    <!-- end:: Evaluation modality and period -->
                  </div>

                  <!-- begin:: Evaluation status -->
                  <div class="d-flex flex-row justify-end">
                    <v-tooltip
                      bottom
                      :color="
                        `${
                          evaluationState(evaluation) == 1
                            ? 'orange'
                            : evaluationState(evaluation) == 2
                            ? 'success'
                            : evaluationState(evaluation) == 3
                            ? 'red'
                            : 'grey'
                        }`
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          class="ma-2"
                          :color="
                            `${
                              evaluationState(evaluation) == 1
                                ? 'orange'
                                : evaluationState(evaluation) == 2
                                ? 'success'
                                : evaluationState(evaluation) == 3
                                ? 'red'
                                : 'grey'
                            }`
                          "
                          outlined
                        >
                          <v-icon left>
                            {{
                              evaluationState(evaluation) == 1
                                ? "mdi-book-clock-outline"
                                : evaluationState(evaluation) == 2
                                ? "mdi-book-arrow-right-outline"
                                : evaluationState(evaluation) == 3
                                ? "mdi-book-check-outline"
                                : "mdi-book"
                            }}
                          </v-icon>
                          {{
                            evaluationState(evaluation) == 1
                              ? "Perfil próximo a realizar"
                              : evaluationState(evaluation) == 2
                              ? "Perfil en ejecución"
                              : evaluationState(evaluation) == 3
                              ? "Perfil cerrado"
                              : "Desconocido"
                          }}
                        </v-chip>
                      </template>
                      <span>{{
                        evaluationState(evaluation) == 1
                          ? "Este perfil aún no se ha comenzado."
                          : evaluationState(evaluation) == 2
                          ? "Este perfil ya se comenzó a trabajar."
                          : evaluationState(evaluation) == 3
                          ? "El periodo de entrega de este perfil ya terminó."
                          : "Ninguna descripción"
                      }}</span>
                    </v-tooltip>
                  </div>
                  <!-- end:: Evaluation status -->
                </v-col>
              </v-row>
              <!-- end::Evaluation Card Title  -->

              <v-divider></v-divider>

              <!-- begin::activity description and evaluation period container -->
              <v-row
                class="d-flex justify-center flex-column flex-sm-row my-n2 px-3"
              >
                <!-- begin::activity description -->
                <v-col
                  cols="12"
                  sm="9"
                  class="d-flex justify-center flex-column pa-0 px-4 px-sm-0"
                >
                  <p class="pa-sm-2 pl-sm-4 mb-0 text-justify ">
                    {{ evaluation.description }}
                  </p>

                  <!-- begin:: Instrumento de evaluación -->
                  <a
                    :href="evaluationInstrumentURL + evaluation.idE"
                    target="_blank"
                    class="pl-sm-4 mt-2 mt-sm-0 mb-3 d-flex justify-center justify-sm-start align-center"
                  >
                    <v-icon class="mr-1 " color="red">mdi-file-pdf-box</v-icon>
                    <span class="font-weight-medium black--text"
                      >Instrumento de evaluación</span
                    >
                  </a>
                  <!-- end:: Instrumento de evaluación -->
                </v-col>
                <!-- end::activity description -->

                <!-- begin::activity global score indicator -->

                <v-row class="ma-0 pa-0 d-flex justify-center align-center">
                  <v-col cols="9" class="ma-0 pa-0"
                    ><ScoreViewer
                      :isUpdated="evaluation.is_updated_score"
                      :score="evaluation.final_score"
                      :replacementScore="0"
                      :notFluid="true"
                      :studentStatus="studentInfo"
                      :subjectType="subjectType"
                    ></ScoreViewer>
                  </v-col>
                </v-row>

                <!-- end::activity global score indicator -->
              </v-row>
              <!-- end::activity description and evaluation period container -->

              <!-- begin::SubEvaluationCard component -->
              <v-row class="px-6 ma-0 mt-5 mb-n3 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                  <p class="text-h6 mb-0 text-center text-sm-left">
                    <v-icon small>mdi-chevron-down</v-icon> Listado de
                    evaluaciones
                    <span>({{ evaluation.sub_evaluations.length }})</span>
                  </p>
                </v-col>
              </v-row>
              <SubEvaluationCard
                :subEvaluationInfo="evaluation"
                :evaluation_StartDate="evaluation.start_date"
                :evaluation_EndDate="evaluation.end_date"
                :subjectType="subjectType"
                :studentInfo="studentInfo"
              ></SubEvaluationCard>
              <!-- end::SubEvaluationCard component -->
            </v-card>
            <!-- end:: Evaluation card, contains the evaluation and sub-evalautions of subjects by period|trimester|module -->

            <!-- end::EvaluationCard component info & details -->
          </v-col>
        </v-row>
        <!-- end::Carpet like evaluations container -->

        <!-- begin:: Fallback alert if there is not a single activity in the fase -->
        <v-row v-else class="blue-grey lighten-4 ma-0 rounded">
          <v-col cols="12" class="mx-0">
            <p class="ma-0 pa-0 text-center font-weight-medium">
              Aún no se han registrado perfiles en esta fase.
            </p>
          </v-col>
        </v-row>
        <!-- begin:: Fallback alert if there is not a single activity in the fase -->
      </div>
    </v-col>
  </v-row>
  <!-- end::EvaluationCard component-->
</template>

<script>
import SubEvaluationCard from "@/view/components/evaluation/detailed/SubEvaluation.vue";
import ScoreViewer from "@/view/components/evaluation/ScoreViewer.vue";

export default {
  name: "EvaluationCard",
  props: {
    evaluationInfo: {
      type: Array,
    },
    studentInfo: {
      type: Number,
    },
    subjectType: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  components: {
    ScoreViewer,
    SubEvaluationCard,
  },
  methods: {
    //checks whether or not the evaluation state is "próximo a realizar", "en ejecución" o "cerrado"
    evaluationState(evaluation) {
      //obtaining today's date in ms
      const today = new Date().getTime();

      //obtaining the end of the evaluation period date in ms
      const evaluationEndDate = new Date(evaluation.end_date);
      evaluationEndDate.setDate(evaluationEndDate.getDate() + 1);

      //obtaining the start of the evaluation period date in ms
      const evaluationStartDate = new Date(evaluation.start_date);
      evaluationStartDate.setDate(evaluationStartDate.getDate());

      //Comparing evaluation period start and end with todays's date to get the evaluation status ID
      if (evaluationStartDate.getTime() - today > 0) {
        //"próximo a realizar"
        return 1;
      } else if (
        evaluationStartDate.getTime() - today < 0 &&
        evaluationEndDate.getTime() - today > 0
      ) {
        //"en ejecución"
        return 2;
      } else if (evaluationEndDate.getTime() - today < 0) {
        //"cerrado"
        return 3;
      }
    },
  },
  computed: {
    evaluationInstrumentURL() {
      let baseURL;
      if (process.env.GE_ENV === "production") {
        baseURL = process.env.GE_API_URL;
      } else if (process.env.GE_ENV === "development") {
        baseURL = process.env.GE_DEV_API_URL;
      } else {
        baseURL = process.env.GE_LOCAL_API_URL;
      }
      return baseURL + "/evaluations/evaluation-instrument/";
    },
  },
};
</script>
