import GeStudentApiService from "@/core/services/geStudentApi.service";

/**
 * GET request to fetch student data (logged user)
 * @returns {*}
 */
export const getStudentInfo = () => {
  return GeStudentApiService.get("auth/me");
};

export default {
  getStudentInfo,
};
