<template>
  <div>
    <!-- begin:: "Go Back" button -->
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a asignaturas
    </v-btn>
    <!-- end:: "Go Back" button -->
    <div
      v-if="isLoadingSubjectInfo && isLoadingEvaluations"
      style="height: 100px;"
      class="white mt-3 mb-6"
    >
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="text-subtitle-1 text-center" cols="12">
          Cargando información de la asignatura...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="blue darken-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>
    <!-- begin:: Selected subject info (will contain period score before and after the recuperation process) -->
    <div
      v-else
      :class="
        `${colors[subjectInfo.subject_id % 12]} card card-custom gutter-b`
      "
    >
      <!-- begin::fallback screen when there´s not subject info -->
      <v-row v-if="empty" class="pa-5">
        <v-col cols="12" sm="8" md="9" class="text-center text-sm-left">
          <div>
            <v-row class="mb-1 mb-sm-1">
              <v-col cols="12" sm="5">
                <div
                  class="d-flex justify-center justify-sm-start align-center"
                  v-if="subjectInfo.subject_type_id == 10"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size red rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-sad-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Nota reprobada (menor a 3.5)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size success rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-happy-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Nota aprobada (mayor o igual a 3.5)</span>
                  </v-tooltip>
                </div>
                <div
                  v-else
                  class="d-flex justify-center justify-sm-start align-center"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size red rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-sad-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Nota reprobada (menor a 5.5)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size yellow darken-2 rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-neutral-outline</v-icon
                        >
                      </div>
                    </template>
                    <span
                      >Nota insuficiente para aprobar según el ITR (entre 5.5 y
                      5.9)</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size success rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-happy-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Nota aprobada (mayor o igual a 6.0)</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>

            <div class="text-h4 font-weight-bold white--text mb-1">
              {{ subjectInfo.subject }}
            </div>
            <div class="font-weight-medium grey--text text--lighten-5">
              {{ subjectInfo.full_name }}
            </div>
            <div class="font-weight-medium grey--text text--lighten-4">
              {{ subjectInfo.email }}
            </div>
            <div class="font-weight-medium grey--text text--lighten-4">
              {{ subjectInfo.clasification_subject }}
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- begin::fallback and loading subject info screen -->

      <!-- begin:: subject information -->
      <v-row v-else class="pa-5 d-flex justify-start">
        <v-col cols="12" sm="8" class="text-center text-sm-left">
          <div>
            <v-row class="mb-1 mb-sm-1">
              <v-col cols="12" sm="5">
                <div
                  class="d-flex justify-center justify-sm-start align-center"
                  v-if="subjectInfo.subject_type_id == 1"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size red rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-sad-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Nota reprobada (menor a 3.5)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size success rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-happy-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Nota aprobada (mayor o igual a 3.5)</span>
                  </v-tooltip>
                </div>
                <div
                  v-else
                  class="d-flex justify-center justify-sm-start align-center"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size red rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-sad-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Nota reprobada (menor a 5.5)</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size yellow darken-2 rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-neutral-outline</v-icon
                        >
                      </div>
                    </template>
                    <span
                      >Nota insuficiente para aprobar según el ITR (entre 5.5 y
                      5.9)</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="fixed-size success rounded-circle mx-3 ml-sm-0 mr-sm-2 d-flex justify-center"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon class="ma-0 pa-0 d-flex align-self-center">
                          mdi-emoticon-happy-outline</v-icon
                        >
                      </div>
                    </template>
                    <span>Nota aprobada (mayor o igual a 6.0)</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>

            <div class="text-h4 font-weight-bold white--text mb-1">
              {{ subjectInfo.subject }}
            </div>
            <div class="font-weight-medium grey--text text--lighten-5">
              {{ subjectInfo.full_name }}
            </div>
            <div class="font-weight-medium grey--text text--lighten-4">
              {{ subjectInfo.email }}
            </div>
            <div class="font-weight-medium grey--text text--lighten-4">
              {{ subjectInfo.clasification_subject }}
            </div>
          </div>
        </v-col>

        <!-- end:: subject information -->
        <!-- begin::accumulated score subjectModuleProgressIndex -->

        <!-- end::accumulated score subjectModuleProgressIndex -->
      </v-row>
    </div>
    <!-- end:: Selected subject info -->
    <div
      v-if="isLoadingEvaluations"
      class="card card-custom gutter-b px-sm-15 py-7"
    >
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="text-subtitle-1 text-center" cols="12">
          Calculando progreso de asignatura...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="blue darken-1"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </div>

    <div v-else class="card card-custom gutter-b px-sm-15 py-md-7">
      <div class="pb-5 pb-md-0" v-if="student.payment_status == 1">
        <v-row v-if="subjectInfo.subject_type_id == 1" class="d-flex">
          <v-col cols="12" class="pl-7 px-6 pb-2 pt-6 pb-md-3 pt-md-0 px-md-0">
            <p class="font-weight-bold text-h5 mb-1">
              Progreso de
              {{ subjectInfo.subject_type_id == 1 ? "módulo" : `asignatura` }}:
            </p>
          </v-col>

          <v-col class="pa-0 ma-0 pa-6 pa-md-0" cols="12" md="4">
            <div class="d-flex justify-start align-center mr-md-3 ">
              <div>
                <v-avatar color="cyan lighten-5" size="85">
                  <v-icon large color="cyan">mdi-book-outline</v-icon>
                </v-avatar>
              </div>
              <!-- -------------------- -->

              <div v-if="completeSubjectDetails.final_score > 0" class="ml-3">
                <p class="text-body-1 font-weight-medium mb-0">
                  <v-chip
                    label
                    :class="
                      `${getScoreColor(
                        subjectInfo.subject_type_id,
                        completeSubjectDetails.final_score
                      )} mb-1`
                    "
                  >
                    <span class="white--text font-weight-bold">
                      {{ completeSubjectDetails.final_score }}
                    </span>
                  </v-chip>
                </p>

                <p class="text-subtitle-1 font-weight-medium mb-0">
                  Acumulado global
                </p>
              </div>

              <v-menu
                v-else
                open-on-hover
                left
                offset-x
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="ma-0 pa-0 ml-3 d-flex flex-column">
                    <p
                      v-bind="attrs"
                      v-on="on"
                      class="text-body-1 font-weight-medium mb-0"
                    >
                      <v-chip label :class="`grey lighten-1 mt-1`">
                        <span class="white--text font-weight-bold">
                          N.P.I
                        </span>
                      </v-chip>
                    </p>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      {{ stageType !== "módulo" ? "Nota" : "Nivel de logro" }}
                      pendiente de ingresar.
                    </p>
                  </div>
                </template>

                <v-card class="pa-4" style="width:300px !important">
                  <p class="text-h6 ma-0 mb-1">
                    {{
                      subjectInfo.subject_type_id == 1
                        ? "Módulo no empezado."
                        : `Promedio de ${stageType} no disponible.`
                    }}
                  </p>
                  <p class="text-body-1 ma-0">
                    {{
                      subjectInfo.subject_type_id == 1
                        ? "Este módulo aún no se ha comenzado a trabajar o evaluar."
                        : `Aún no se han comenzado a evaluar los perfiles de este ${stageType}.`
                    }}
                  </p>
                </v-card>
              </v-menu>
            </div>
          </v-col>

          <v-col
            class="pa-0 ma-0 pa-6 pa-md-0"
            cols="12"
            md="4"
            v-if="completeSubjectDetails.final_recovery_score > 0"
          >
            <div class="d-flex justify-start align-center mr-md-3">
              <div>
                <v-avatar color="indigo lighten-5" size="85">
                  <v-icon large color="indigo">mdi-book-plus-outline</v-icon>
                </v-avatar>
              </div>
              <div class="ml-3">
                <p class="text-body-1 font-weight-medium mb-0">
                  <v-chip
                    label
                    :class="
                      `${getScoreColor(
                        subjectInfo.subject_type_id,
                        completeSubjectDetails.final_recovery_score
                      )} mb-1`
                    "
                  >
                    <span class="white--text font-weight-bold">
                      {{ completeSubjectDetails.final_recovery_score }}
                    </span>
                  </v-chip>
                </p>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  Acumulado global con nota de recuperación ordinaria
                </p>
              </div>
            </div>
          </v-col>

          <v-col
            class="pa-0 ma-0 pa-6 pa-md-0"
            cols="12"
            md="4"
            v-if="completeSubjectDetails.extraordinary_final_score > 0"
          >
            <div class="d-flex justify-start align-center mr-md-3">
              <div>
                <v-avatar color="purple lighten-5" size="85">
                  <v-icon large color="purple">mdi-book-plus-outline</v-icon>
                </v-avatar>
              </div>
              <div class="ml-3">
                <p class="text-body-1 font-weight-medium mb-0">
                  <v-chip
                    label
                    :class="
                      `${getScoreColor(
                        subjectInfo.subject_type_id,
                        completeSubjectDetails.extraordinary_final_score
                      )} mb-1`
                    "
                  >
                    <span class="white--text font-weight-bold">
                      {{ completeSubjectDetails.extraordinary_final_score }}
                    </span>
                  </v-chip>
                </p>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  Acumulado global con nota de recuperación extraordinaria
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- -----------------ACADEMIC SUBJECTS----------------- -->
        <v-row cols="12" v-if="subjectInfo.subject_type_id == 2" class="d-flex">
          <v-col cols="12" class="pl-7 px-6 pb-2 pt-6 pb-md-3 pt-md-0 px-md-0">
            <p class="font-weight-bold text-h5 mb-1">
              Progreso de
              {{ subjectInfo.subject_type_id == 1 ? "módulo" : `asignatura` }}:
            </p>
          </v-col>

          <v-col class="pa-0 ma-0 px-6 py-2 py-md-0 px-md-0" cols="12" md="6">
            <div class="d-flex justify-start align-center mr-md-3 ">
              <div>
                <v-avatar color="cyan lighten-5" size="85">
                  <v-icon large color="cyan">mdi-book-outline</v-icon>
                </v-avatar>
              </div>
              <!-- -------------------- -->

              <div v-if="completeSubjectDetails.final_score > 0" class="ml-3">
                <p class="text-body-1 font-weight-medium mb-0">
                  <v-chip
                    label
                    :class="
                      `${getScoreColor(
                        subjectInfo.subject_type_id,
                        completeSubjectDetails.final_score
                      )} mb-1`
                    "
                  >
                    <span class="white--text font-weight-bold">
                      {{ completeSubjectDetails.final_score }}
                    </span>
                  </v-chip>
                </p>

                <p class="text-subtitle-1 font-weight-medium mb-0">
                  Acumulado global
                </p>
              </div>

              <v-menu
                v-else
                open-on-hover
                left
                offset-x
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="ma-0 pa-0 ml-3 d-flex flex-column">
                    <p
                      v-bind="attrs"
                      v-on="on"
                      class="text-body-1 font-weight-medium mb-0"
                    >
                      <v-chip label :class="`grey lighten-1 mt-1`">
                        <span class="white--text font-weight-bold">
                          N.P.I
                        </span>
                      </v-chip>
                    </p>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      Acumulado global pendiente de calcular.
                    </p>
                  </div>
                </template>

                <v-card class="pa-4" style="width:300px !important">
                  <p class="text-h6 ma-0 mb-1">
                    {{
                      subjectInfo.subject_type_id == 1
                        ? "Módulo no empezado."
                        : `Promedio de ${stageType}s no disponible.`
                    }}
                  </p>
                  <p class="text-body-1 ma-0">
                    {{
                      subjectInfo.subject_type_id == 1
                        ? "Este módulo aún no se ha comenzado a trabajar o evaluar."
                        : `Aún no se han comenzado a evaluar los ${stageType}s.`
                    }}
                  </p>
                </v-card>
              </v-menu>
            </div>
          </v-col>

          <v-col
            class="pa-0 ma-0 px-6 py-2 py-md-0 px-md-0"
            cols="12"
            md="6"
            v-if="completeSubjectDetails.extraordinary_final_score > 0"
          >
            <div class="d-flex justify-start align-center mr-md-3">
              <div>
                <v-avatar color="purple lighten-5" size="85">
                  <v-icon large color="purple">mdi-book-plus-outline</v-icon>
                </v-avatar>
              </div>
              <div class="ml-3">
                <p class="text-body-1 font-weight-medium mb-0">
                  <v-chip
                    label
                    :class="
                      `${getScoreColor(
                        subjectInfo.subject_type_id,
                        completeSubjectDetails.extraordinary_final_score
                      )} mb-1`
                    "
                  >
                    <span class="white--text font-weight-bold">
                      {{ completeSubjectDetails.extraordinary_final_score }}
                    </span>
                  </v-chip>
                </p>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  Acumulado global con nota de recuperación extraordinaria
                </p>
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- --------AVANZO SCORES------------- -->
        <v-divider
          v-if="completeSubjectDetails.avanzo_score > 0"
          class="mt-8 mb-4"
        ></v-divider>

        <v-row
          cols="12"
          v-if="completeSubjectDetails.avanzo_score > 0"
          class="d-flex"
        >
          <v-col cols="12" class="pl-7 pl-md-0">
            <p class="font-weight-bold text-h5 mb-0">
              Resultados de prueba Avanzo:
            </p>
          </v-col>

          <v-col class="pa-0 ma-0 px-6 py-2 py-md-0 px-md-0" cols="12" md="6">
            <div class="d-flex justify-start align-center mr-md-3 ">
              <div>
                <v-avatar color="indigo lighten-5" size="85">
                  <v-icon large color="indigo">mdi-school-outline</v-icon>
                </v-avatar>
              </div>
              <!-- -------------------- -->

              <div v-if="completeSubjectDetails.avanzo_score > 0" class="ml-3">
                <p class="text-body-1 font-weight-medium mb-0">
                  <v-chip
                    label
                    :class="
                      `${getScoreColor(
                        subjectInfo.subject_type_id,
                        completeSubjectDetails.avanzo_score
                      )} mb-1`
                    "
                  >
                    <span class="white--text font-weight-bold">
                      {{ completeSubjectDetails.avanzo_score }}
                    </span>
                  </v-chip>
                </p>

                <p class="text-subtitle-1 font-weight-medium mb-0">
                  Nota obtenida en prueba Avanzo.
                </p>
              </div>

              <v-menu
                v-else
                open-on-hover
                left
                offset-x
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="ma-0 pa-0 ml-3 d-flex flex-column">
                    <p
                      v-bind="attrs"
                      v-on="on"
                      class="text-body-1 font-weight-medium mb-0"
                    >
                      <v-chip label :class="`grey lighten-1 mt-1`">
                        <span class="white--text font-weight-bold">
                          N.P.I
                        </span>
                      </v-chip>
                    </p>
                    <p class="text-subtitle-1 font-weight-medium mb-0">
                      nota de prueba Avanzo pendiente de ingresar.
                    </p>
                  </div>
                </template>

                <v-card class="pa-4" style="width:300px !important">
                  <p class="text-h6 ma-0 mb-1">
                    nota de prueba Avanzo
                  </p>
                  <p class="text-body-1 ma-0">
                    nota de prueba Avanzo pendiente de ingresar.
                  </p>
                </v-card>
              </v-menu>
            </div>
          </v-col>

          <v-col
            class="pa-0 ma-0 px-6 py-2 px-md-0 py-md-0"
            cols="12"
            md="6"
            v-if="completeSubjectDetails.final_score_with_avanzo_score > 0"
          >
            <div class="d-flex justify-start align-center mr-md-3">
              <div>
                <v-avatar color="deep-purple lighten-5" size="85">
                  <v-icon large color="deep-purple">mdi-school-outline</v-icon>
                </v-avatar>
              </div>
              <div class="ml-3">
                <p class="text-body-1 font-weight-medium mb-0">
                  <v-chip
                    label
                    :class="
                      `${getScoreColor(
                        subjectInfo.subject_type_id,
                        completeSubjectDetails.final_score_with_avanzo_score
                      )} mb-1`
                    "
                  >
                    <span class="white--text font-weight-bold">
                      {{ completeSubjectDetails.final_score_with_avanzo_score }}
                    </span>
                  </v-chip>
                </p>
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  Acumulado global con nota obtenida en prueba Avanzo.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row
          :class="`d-flex flex-column flex-md-row mr-6 my-1 ml-1 rounded-lg `"
        >
          <v-col class="pa-0 ma-0" cols="12">
            <div class="d-flex justify-start align-center mr-md-3">
              <div>
                <v-avatar color="orange lighten-5" size="80">
                  <v-icon large color="orange">mdi-clock-alert-outline</v-icon>
                </v-avatar>
              </div>
              <div class="ml-3">
                <p class="text-subtitle-1 font-weight-medium mb-0">
                  En nuestros registros se refleja mora en el pago.
                </p>
                <p class="text-body-1 font-weight-normal mb-0">
                  Si existe error favor comunicarse al 2234-6030 ó al correo
                  contabilidad@ricaldone.edu.sv y anexando un comprobante de
                  pago válido.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <!-- begin::Card Header -->
      <div
        v-show="!isLoadingEvaluations"
        class="card-header flex-nowrap border-0 pt-6 pb-0"
      >
        <v-row>
          <v-col cols="12" sm="7" class="pl-sm-10">
            <p
              class="ma-0 pa-0 text-h5 text-center text-sm-left font-weight-medium"
            >
              Perfiles
            </p>
            <p
              class="d-block text-body-1 text-muted ma-0 pa-0 text-center text-md-left font-weight-normal"
            >
              Haz click o toca la pantalla para ver los perfiles de cada etapa.
            </p>
          </v-col>
          <v-col
            cols="12"
            sm="5"
            class="d-none pr-sm-8 d-flex justify-center justify-sm-end"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2 d-none"
                  color="primary"
                  outlined
                  pill
                  v-bind="attrs"
                  v-on="on"
                  link
                  to="/process_info"
                >
                  <v-icon left>
                    mdi-information-outline
                  </v-icon>
                  Más información
                </v-chip>
              </template>
              <span>¿Tienes dudas? Haz click aqui.</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <div class="card-toolbar"></div>
      </div>
      <!-- end::Card Header -->

      <!-- begin::Card Body -->
      <div class="card-body px-3">
        <!-- begin:: Evaluations Loader -->
        <div v-if="isLoadingEvaluations" style="height: 200px;">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Cargando perfiles...
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                color="blue darken-1"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </div>
        <!-- end:: Evaluations Loader -->

        <!-- begin:: Collapsible container for evaluations -->
        <v-row v-else class="d-flex justify-center mx-n5">
          <v-col cols="12" lg="11" v-if="!empty">
            <v-expansion-panels v-model="collapsibleActiveState" multiple>
              <v-expansion-panel
                v-for="(stage, stgIndex) in allEvaluations"
                :key="stgIndex"
              >
                <v-expansion-panel-header>
                  <v-row
                    v-if="currentUserPersonalInfo.payment_status == 1"
                    :class="
                      `d-flex flex-column flex-md-row mr-6 my-1 ml-1 rounded-lg `
                    "
                  >
                    <!-- begin:: Stage name and weight -->
                    <v-col class="d-flex px-0 py-0 mb-2" cols="12">
                      <p class="pa-0 ma-0 text-h6 font-weight-bold">
                        {{ stage.stage }} ({{ stage.percent }}%)
                      </p>
                    </v-col>
                    <!-- end:: Stage name and weight -->

                    <v-col
                      v-if="stageType !== 'módulo'"
                      class="pa-0 ma-0"
                      cols="12"
                      md="5"
                    >
                      <div class="d-flex justify-start align-center mr-md-3">
                        <div>
                          <v-avatar color="blue lighten-5" size="80">
                            <v-icon large color="blue"
                              >mdi-tag-text-outline</v-icon
                            >
                          </v-avatar>
                        </div>
                        <!-- -------------------- -->
                        <div v-if="stage.final_score > 0" class="ml-3">
                          <!-- <div v-if="stage.is_updated_score" class="ml-3"> -->
                          <p class="text-subtitle-1 font-weight-medium mb-0">
                            Nota final de {{ stageType }}
                          </p>
                          <p class="text-body-1 font-weight-medium mb-0">
                            <v-chip
                              label
                              :class="
                                `${getScoreColor(
                                  subjectInfo.subject_type_id,
                                  stage.final_score
                                )} mt-1`
                              "
                            >
                              <span class="white--text font-weight-bold">
                                {{ stage.final_score }}
                              </span>
                            </v-chip>
                          </p>
                        </div>

                        <v-menu
                          v-else
                          open-on-hover
                          left
                          offset-x
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div class="ma-0 pa-0 ml-3 d-flex flex-column">
                              <p
                                class="text-subtitle-1 font-weight-medium mb-0"
                              >
                                {{
                                  stageType !== "módulo"
                                    ? "Nota"
                                    : "Nivel de logro"
                                }}
                                pendiente de ingresar.
                              </p>
                              <p
                                v-bind="attrs"
                                v-on="on"
                                class="text-body-1 font-weight-medium mb-0"
                              >
                                <v-chip label :class="`grey lighten-1 mt-1`">
                                  <span class="white--text font-weight-bold">
                                    N.P.I
                                  </span>
                                </v-chip>
                              </p>
                            </div>
                          </template>

                          <v-card class="pa-4" style="width:300px !important">
                            <p class="text-h6 ma-0 mb-1">
                              {{
                                subjectInfo.subject_type_id == 1
                                  ? "Módulo no empezado."
                                  : `Promedio de ${stageType} no disponible.`
                              }}
                            </p>
                            <p class="text-body-1 ma-0">
                              {{
                                subjectInfo.subject_type_id == 1
                                  ? "Este módulo aún no se ha comenzado a trabajar o evaluar."
                                  : `Aún no se han comenzado a evaluar los perfiles de este ${stageType}.`
                              }}
                            </p>
                          </v-card>
                        </v-menu>
                      </div>
                    </v-col>

                    <v-divider
                      vertical
                      class="d-none d-md-block ml-n1 mr-3"
                      v-if="stage.recovery_score > 0"
                    ></v-divider>
                    <v-divider
                      class="d-md-none"
                      v-if="stage.recovery_score > 0"
                    >
                    </v-divider>

                    <v-col
                      v-if="stage.recovery_score > 0 && stageType !== 'módulo'"
                      class="pa-0 ma-0"
                      cols="12"
                      md="5"
                    >
                      <div class="d-flex justify-start align-center mr-md-3">
                        <div>
                          <v-avatar color="deep-orange lighten-5" size="80">
                            <v-icon large color="deep-orange"
                              >mdi-tag-plus-outline</v-icon
                            >
                          </v-avatar>
                        </div>
                        <div class="ml-3">
                          <p class="text-subtitle-1 font-weight-medium mb-0">
                            {{
                              stageType !== "módulo" ? "Nota" : "Nivel de logro"
                            }}
                            de {{ stageType }} con nota de recuperación
                            ordinaria
                          </p>
                          <p class="text-body-1 font-weight-medium mb-0">
                            <v-chip
                              label
                              :class="
                                `${getScoreColor(
                                  subjectInfo.subject_type_id,
                                  stage.recovery_score
                                )} mt-1`
                              "
                            >
                              <span class="white--text font-weight-bold">
                                {{ stage.recovery_score }}
                              </span>
                            </v-chip>
                          </p>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    v-else
                    :class="
                      `d-flex flex-column flex-md-row mr-6 my-1 ml-1 rounded-lg `
                    "
                  >
                    <!-- begin:: Stage name and weight -->
                    <v-col class="d-flex px-0 py-0 mb-2" cols="12">
                      <p class="pa-0 ma-0 text-h6 font-weight-bold">
                        {{ stage.stage }} ({{ stage.percent }}%)
                      </p>
                    </v-col>
                    <!-- end:: Stage name and weight -->

                    <v-col class="pa-0 ma-0" cols="12">
                      <div class="d-flex justify-start align-center mr-md-3">
                        <div>
                          <v-avatar color="orange lighten-5" size="80">
                            <v-icon large color="orange"
                              >mdi-clock-alert-outline</v-icon
                            >
                          </v-avatar>
                        </div>
                        <div class="ml-3">
                          <p class="text-subtitle-1 font-weight-medium mb-0">
                            En nuestros registros se refleja mora en el pago.
                          </p>
                          <p class="text-body-1 font-weight-normal mb-0">
                            Si existe error favor comunicarse al 2234-6030 ó al
                            correo contabilidad@ricaldone.edu.sv y anexando un
                            comprobante de pago válido.
                          </p>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pb-2 pb-sm-4 mx-0">
                  <v-row
                    v-if="stage.categories.length > 0"
                    class="white rounded-lg px-0 px-sm-3 "
                  >
                    <!-- begin::EvaluationCard component -->
                    <v-col>
                      <EvaluationCard
                        :evaluationInfo="stage.categories"
                        :studentInfo="student.payment_status"
                        :subjectType="subjectInfo.subject_type_id"
                      >
                      </EvaluationCard>
                    </v-col>

                    <!-- end::EvaluationCard component -->
                  </v-row>
                  <v-row v-else>
                    <v-card
                      class="elevation-0 px-10 py-6 mt-8 mx-auto"
                      outlined
                    >
                      <p class="mb-0">
                        Aún no se ha creado ningún perfil.
                      </p>
                    </v-card>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col v-else class="px-15">
            <v-banner outlined rounded class=" pa-0 mb-2">
              <p class="text-center ma-0 text-h6 opacity-70 font-weight-normal">
                ¡No hay ningún perfil habilitado aún!
              </p>
            </v-banner>
          </v-col>
        </v-row>
        <!-- end:: Collapsible container for evaluations -->
      </div>
      <!-- End::Card Body -->
    </div>
    <!-- end::Card -->
  </div>
</template>

<script>
import evaluationRepository from "@/repositories/evaluationRepository";
import subjectAndModuleRepository from "@/repositories/subjectAndModuleRepository";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PURGE_SUBJECT } from "@/core/services/store/subject.module";
import EvaluationCard from "@/view/components/evaluation/detailed/Evaluation.vue";
import studentInfoRepository from "@/repositories/studentInfoRepository.js";
import ScoreViewer from "@/view/components/evaluation/ScoreViewer.vue";
import { mapGetters } from "vuex";
export default {
  name: "StudentScores",
  title: "Perfiles de la asignatura | PE ITR",
  props: {
    //subject id
    s: {
      type: String,
    },
  },
  components: {
    studentInfoRepository,
    EvaluationCard,
    ScoreViewer,
  },
  data() {
    return {
      //all data from request saves here
      completeSubjectDetails: {},
      allEvaluations: [],
      subjectInfo: {},
      student: {},
      //loading condition
      isLoadingEvaluations: false,
      isLoadingSubjectInfo: false,
      //Empty request validator
      empty: true,
      //computed accumulated data
      subjectModuleProgressIndex: "",

      collapsibleActiveState: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Asignaturas", route: "view_subjects_modules" },
      { title: "Perfiles", route: "student_scores" },
    ]);
    //The request will be made when the page is loaded
    this.loadAllEvaluationsBySubject();
    this.loadSubjectInfo();
    this.studentInfo();
  },
  methods: {
    studentInfo() {
      studentInfoRepository
        .getStudentInfo()
        .then(({ data }) => {
          this.student = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        });
    },
    //subject info request
    loadSubjectInfo() {
      this.isLoadingSubjectInfo = true;
      subjectAndModuleRepository
        .getSubjectbyTeacherId(this.s)
        .then(({ data }) => {
          //saving data
          this.subjectInfo = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.isLoadingSubjectInfo = false;
        });
    },

    //evaluations request
    loadAllEvaluationsBySubject() {
      this.isLoadingEvaluations = true;
      evaluationRepository
        .getAllEvaluationsBySubject(this.s)
        .then(({ data }) => {
          if (data.stages.length > 0) {
            //saving data
            this.completeSubjectDetails = data;
            this.allEvaluations = data.stages;
            var stageAccumulated = [];
            var parsedArray = [];

            //foreach
            this.allEvaluations.forEach((el) => {
              //Array from all accumulated
              stageAccumulated.push(el.final_score);
            });

            //Converting from string to number
            parsedArray = stageAccumulated.map((str) => {
              return parseFloat(str);
            });

            //Adding al accumulateds
            this.subjectModuleProgressIndex =
              parsedArray.reduce((a, b) => a + b) / this.allEvaluations.length;

            this.empty = false;
          } else {
            this.empty = true;
          }
        })
        .catch((err) => {
          console.warn(err);
          this.fireToast({
            icon: "error",
            title: "Hubo un error al intentar obtener los datos",
          });
        })
        .finally(() => {
          this.isLoadingEvaluations = false;
        });
    },

    //when triggered takes the router position back by 1 position
    goBack() {
      this.$router.go(-1);
    },
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    stageType() {
      if (this.student.grade_id > 3) {
        return this.subjectInfo.subject_type_id == 2 ? "periodo" : "módulo";
      } else {
        return this.subjectInfo.subject_type_id == 2 ? "trimestre" : "módulo";
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch(PURGE_SUBJECT);
  },
};
</script>

<style scoped>
.fixed-size {
  height: 26px;
  width: 26px;
}
</style>
